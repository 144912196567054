.notesPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notesGrid{
    width: 100%;
}

.note{
    height: 200px;
    min-width: 150px;
    transition: box-shadow .2s ease-in-out;
    cursor: pointer;
}

.note:hover{
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}